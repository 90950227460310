<template>
  <el-main>
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane :label="item.name" :name="String(index)" v-for="(item, index) in list" :key="index"></el-tab-pane>
    </el-tabs>
    <RichText ref="richText" :richTxt="content" @soninfo="val => (content = val)"></RichText>
    <Preservation @preservation="preservation"></Preservation>
  </el-main>
</template>

<script>
import RichText from '@/components/richText';
import Preservation from '@/components/preservation';
export default {
  components: {
    RichText,
    Preservation,
  },
  data() {
    return {
      activeName: '',
      list: [],
      content: '',
      type: '',
    };
  },
  created() {
    this.$axios.post(this.$api.gateway.member.setList).then(res => {
      if (res.code == 0) {
        this.list = res.result.list;
        this.list.map(item => (item.id = String(item.id)));
        this.type = this.list[0].type;
        this.content = this.list[0].content;
      }
    });
  },
  methods: {
    handleClick() {
      this.$refs.richText.is_editor = true;
      this.content = this.list[this.activeName].content;
      this.type = this.list[this.activeName].type;
    },
    preservation() {
      this.$axios
        .post(this.$api.gateway.member.ruleSet, {
          type: this.type,
          content: this.content,
        })
        .then(res => {
          if (res.code == 0) {
            this.$message.success('保存成功');
          } else {
            this.$message.error(res.msg);
          }
        });
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  background: #fff;
}
</style>